import React from "react";
import "../datenschutz.css";

const DatenHeader = () => {
  return (
    <div className="jg__daten section__padding">
      <h1>Datenschutzerklärung</h1>
    </div>
  );
};

export default DatenHeader;
