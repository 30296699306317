import image1 from './anleitung1.PNG';
import image2 from './anleitung2.PNG';
import image3 from './anleitung3.PNG';
import image4 from './anleitung4.PNG';
import image5 from './anleitung5.PNG';
import image6 from './anleitung6.PNG';
import image7 from './anleitung7.PNG';

const images = [image1, image2, image3, image4, image5, image6, image7];

export default images;