import React from "react";

const WahlHeader = () => {
  return (
    <div className="jg__wahl section__padding gradient__text">
      <h1>Wahlanleitung</h1>
    </div>
  );
};

export default WahlHeader;
